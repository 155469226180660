import React, { useEffect, useState } from 'react';
import './style.scss'

import { DeleteButton, EditButton, EyeButton, Plus } from '../assets/icons';
import { _Object } from 'utils/interfaces';
import { gemsService } from 'services/gems.service';
import { capitalizeFirstLetter, closeModal, getSortIcon, handleFilters, truncateStringByWords } from 'utils';
import Pagination from 'views/components/form-inputs/pagination';
import AddEditGems from './add';
import OpenGemsDetails from './edit';
import { DeleteItem } from 'views/components';
import { toast } from 'react-toastify';
import DropdownFilter from 'views/components/form-inputs/dropdown-filter';

const Gems = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [gems, setGems] = useState<_Object>({});
	const [selectedGems, setSelectedGems] = useState<any>('');
	const [filterData, setFilterData] = useState<_Object>({
		page: 1,
		sort: '-created_at'
	});
	const getGems = () => {
		setLoading(true)
		gemsService.getGems(filterData).then((data) => {
			setGems(data)
			setLoading(false)
		})
	}

	useEffect(() => {
		getGems()
	}, [filterData])

	// const formik = useFormik({
	// 	initialValues: filterData,
	// 	enableReinitialize: true,
	// 	onSubmit: (values: _Object) => {
	// 		if (values.q.trim() !== '') {
	// 			setFilterData((prev) => ({
	// 				...prev,
	// 				q: values.q,
	// 				page: 1
	// 			}));
	// 		}
	// 	}
	// });

	const deleteItem = () => {
		setLoading(true)
		gemsService.deleteGems(selectedGems).then((data: _Object) => {
			if (!data.error) {
				setLoading(false)
				toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
				getGems()
				closeModal('DeleteGems')
			} else {
				toast.error(data?.message);
			}
		})
	}

	useEffect(() => {
		const element = document.getElementById('addEditModalClose');
		const handleClick = () => {
			console.log('Element clicked!');
			setSelectedGems('')
		};

		if (element) {
			element.addEventListener('click', handleClick);
		}

		return () => {
			if (element) {
				element.removeEventListener('click', handleClick);
			}
		};
	}, []);

	return (
		<>
			<section className="gems-section">
				<div className="container-fluid">
					<div className="row">
						<div className="card">
							<div className="card-header space-between align-middles">
								<h6>Gemstones</h6>
								<div>
									{filterData.filter && <button
										type="button"
										className="btn btn-primary me-3"
										onClick={() => {
											setFilterData({
												page: 1,
												sort: '-created_at'
											})
										}}
									>Clear All</button>}
									<button
										type="button"
										className="btn btn-primary"
										data-bs-toggle="modal"
										data-bs-target="#AddEditGems"
										onClick={() => { setSelectedGems('') }}
									>
										<img src={Plus} alt="Add" width={15} height={15} />&nbsp;
										Add New
									</button>
								</div>
							</div>

							<div className="card-body">
								<div className="table-responsive">
									<table className="table w-100">
										<thead>
											<tr>
												<th style={{ width: '36px' }}>#</th>
												<th style={{ width: '170px' }}>
													<button
														className="btn p-0 border-0"
														onClick={() => { handleFilters('sort', filterData.sort === '-title' ? 'title' : '-title', setFilterData) }}>
														Title
														{getSortIcon(filterData.sort.includes('title') ? filterData.sort : '')}
													</button>
												</th>

												<th style={{ width: '278px' }}>Description</th>
												<th style={{ whiteSpace: 'nowrap', width: '199px' }}>How to use</th>
												<th style={{ width: '200px' }}>Benefits</th>
												<DropdownFilter
													title="Status"
													options={[
														{ label: 'Published', value: 'published' },
														{ label: 'Draft', value: 'draft' }
													]}
													filterKey="status"
													filterData={filterData}
													setFilterData={setFilterData}
												/>
												<th className="action-button" style={{ width: '170px' }}>Action</th>
											</tr>
										</thead>

										<tbody>
											{loading ?
												(
													Array.from({ length: 5 }).map((_, index) => (
														<tr key={index}>
															{Array.from({ length: 7 }).map((_, cellIndex) => (
																<td className="placeholder-glow" key={cellIndex}>
																	<span className="placeholder col-12"></span>
																</td>
															))}
														</tr>
													))
												) :
												gems?.items?.length === 0 ?
													<tr>
														<td colSpan={12} className="text-center text-capitalize">
															No record found!
														</td>
													</tr>
													:
													gems?.items?.map((item: any, i: number) => {
														return (
															<>
																<tr key={i}>
																	<td>{i + 1}</td>

																	<td>
																		<p className="mb-1 text-nowrap text-capitalize">

																			{item?.images?.path && <img src={`${item?.images?.path}`} className="gems-avtar me-2 object-fit-cover" style={{ borderRadius: '30px' }} height={50} width={50} />}
																			{truncateStringByWords(item.title, 10)}
																		</p>
																	</td>

																	<td style={{ width: '270px' }}>
																		<p className="mb-1 text-break text-capitalize" dangerouslySetInnerHTML={{ __html: item.description ? truncateStringByWords(item.description, 10) : '-' }}>
																		</p>
																	</td>
																	<td>
																		<p
																			className="mb-1 text-break text-capitalize remove-list-style"
																			dangerouslySetInnerHTML={{ __html: item.how_to_use ? truncateStringByWords(item.how_to_use, 8) : '-' }}
																		/>
																	</td>

																	<td>
																		<p
																			className="mb-1 text-capitalize remove-list-style"
																			dangerouslySetInnerHTML={{ __html: item.benefits ? truncateStringByWords(item.benefits, 8) : '-' }}
																		/>
																	</td>

																	<td>
																		<p className="mb-1">
																			{item.status ? capitalizeFirstLetter(item.status) : '-'}
																		</p>
																	</td>
																	<td>
																		<ul className="list-inline">
																			<li className="list-inline-item grid justify-content-end my-1">
																				<button type="button" onClick={() => setSelectedGems(item?.id)} className="btn btn-success eye-edit p-0" data-bs-toggle="modal" data-bs-target="#AddEditGems">
																					<img src={EditButton} alt="Edit" width={22} height={16} />
																				</button>
																			</li>

																			<li className="list-inline-item grid justify-content-end my-1">
																				<a href={'#'} className="btn btn-secondary eye-button p-0"
																					data-bs-toggle="modal"
																					data-bs-target="#OpenGemsDetails"
																					onClick={() => setSelectedGems(item?.id)}
																				>
																					<img src={EyeButton} alt="Eye" width={22} height={20} />
																				</a>
																			</li>
																			<li className="list-inline-item grid justify-content-end my-1">
																				<button type="button" onClick={() => setSelectedGems(item?.id)} className="btn btn-primary p-0 eye-delete" data-bs-toggle="modal" data-bs-target="#DeleteGems">
																					<img src={DeleteButton} alt="Delete" width={16} height={16} />
																				</button>
																			</li>
																		</ul>
																	</td>
																</tr>
															</>
														)
													})}

										</tbody>
									</table>
								</div>

							</div>
							{gems?.pagination?.total_pages > 1 &&
								<div className="card-footer">
									<div className="pagination-wrap">
										<Pagination
											total_pages={gems?.pagination?.total_pages}
											current_page={gems?.pagination?.current_page}
											onClick={(i: { [key: string]: number }) =>
												setFilterData((prev) => ({
													...prev,
													page: i.selected + 1
												}))
											}
										/>
									</div>
								</div>}
						</div>

					</div>
				</div>
			</section>

			<AddEditGems getGems={getGems} selectedGems={selectedGems} />
			<DeleteItem title={'Gemstone'} modalId={'DeleteGems'} deleteItem={deleteItem} />
			<OpenGemsDetails getGems={getGems} selectedGems={selectedGems} />
		</>
	)
}

export default Gems